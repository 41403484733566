.skills_grid {
    display: grid;
    grid-template: 60px 1fr/ repeat(12, 1fr);
    width: 100%;
    height: 100%; 
}

#education_section {
    grid-area: 2 / 2 / -1 / span 6;
}

#tech_section {
    grid-area: 2 / 9 / -1 / -2;
}


@media only screen and (max-width: 1200px) {

    #education_section {
        grid-area: 2 / 2 / -1 / -2;
        margin-bottom: 60px;
    }
    
    #tech_section {
        grid-area: 3 / 2 / -1 / -2;
    }
}

@media only screen and (max-width: 650px) {

    #education_section {
        grid-area: 2 / 1 / -1 / -1;
        margin-bottom: 60px;
    }
    
    #tech_section {
        grid-area: 3 / 1 / -1 / -1;
    }
}