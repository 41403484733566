nav {
    background-color: black;
    width: 100%;
}

#logo {
    width: 300px;

}

ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 50%;
    float: right;
    z-index: 5;
}

li {
    margin: 45px 30px 20px 40px;
    padding: 5px;
    font-size: 2.5em;
    border-bottom: 3px solid black;
}

a {
    color: white;
    text-decoration: none;
}

li:hover {
    border-bottom: 3px solid red;
}


@media only screen and (max-width: 1200px) {

    #logo {
        width: 250px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 60%;
    }

    li {
        margin: 45px 10px 20px 40px;
        padding: 5px;
        font-size: 2em;
    }   
}

@media only screen and (max-width: 780px) {

    #logo {
        display: none;
    }

    ul {
        width: 100%;
        background-color:black;
    }   

    li {
        margin: 35px 10px 20px 35px;
        padding: 5px;
        font-size: 2em;
    }   
}


@media only screen and (max-width: 450px) {
    li {
        margin: 35px 10px 20px 35px;
        padding: 5px;
        font-size: 1.5em;
    } 
}


@media only screen and (max-width: 375px) {
    li {
        margin: 20px 10px 20px 35px;
        padding: 5px;
        font-size: 1em;
    } 
}
